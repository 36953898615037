import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import LunchContent from '../components/ContentPages/LunchContent';

function LunchPage({ data, location }) {
  const sanityContent = data.lunchpage;
  return (
    <Layout location={location} lang="no">
      {sanityContent && (
        <LunchContent sanityContent={sanityContent} lang="No" />
      )}
    </Layout>
  );
}

export const query = graphql`
  query LunchPageNo {
    lunchpage: sanityPage(id: { eq: "-d1a9addb-3e3c-5f33-bfca-ba8b36f07fb9" }) {
      id
      titleNo
      description {
        _rawTextNo
      }
      mainImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
        alt
      }
      contentSections {
        ... on SanityMenu {
          id
          menuSectionsOne {
            headingNo
            menuItems {
              descriptionNo
              nameNo
              price
              allergensNo
              hidden
            }
          }
          menuSectionsTwo {
            headingNo
            menuItems {
              descriptionNo
              nameNo
              price
              allergensNo
              hidden
            }
          }
        }
      }
    }
  }
`;

export default LunchPage;
